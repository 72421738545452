import Cookies from 'js-cookie';
import { useRoute } from 'vue-router';
import { getUrlParams } from '.';
import { getStoreInfo } from './api';
import { Token } from './variables';

/**
 * 获取接口的域名（只能在组件内使用）
 * @returns 拼接后的域名
 */
export function api(): string {
  let curr_domain = window.location.host;
  console.log(curr_domain)
  if (curr_domain.indexOf('shopxsy') > -1) {
    if (Cookies.get(Token.ApiToken)) {
      return `//${getStoreId()}-${getPluginName()}.${hrefPrefix()}-apps.shopxsy.com`;
    } else {
      return `//${getStoreId()}-${getPluginName()}.${hrefPrefix()}-apps.shopxsy.com`;
    }
  } else {
    if (Cookies.get(Token.ApiToken)) {
      return `//${getStoreId()}-${getPluginName()}.${hrefPrefix()}-apps.dearlovertech.com`;
    } else {
      return `//${getStoreId()}-${getPluginName()}.${hrefPrefix()}-apps.dearlovertech.com`;
    }
  }
}

function hrefPrefix(): string {
  let ret = 'cn01';
  const tar = location.href.split('//')[1].split('-').shift();
  if (tar === 'us01' || tar === 'us02') {
    ret = tar;
  }

  return ret;
}

/**
 *  获取 店铺ID （只能在组件内使用）
 * @returns curPlugin 店铺ID
 */
export function getStoreId(): string {
  const route = useRoute();
  return route.params.storeId as string;
}

/**
 * 获取 插件名称 (只能在组件内使用)
 * @returns curPlugin 插件名称
 */
export function getPluginName(): string {
  const route = useRoute();

  const curPlugin = route.path.split('/')[2];

  return curPlugin;
}

/**
 *  获取拼接好的完整路由（只能在组件内使用）
 * @param {String} curRoute 该参数将与店铺ID、插件名拼接出完整的路由名
 * @returns router 可用于 routelink 进行跳转
 */
export function getRoute(curRoute: string): string {
  return `/${getStoreId()}/${getPluginName()}/${curRoute}`;
}

/**
 * 获取店铺商城域名（只能在组件内使用）
 * @returns 店铺商城前台域名
 */
export async function getDomain() {
  if (Cookies.get(Token.Token)) {
    const res = await getStoreInfo({ store_id: getStoreId() });
    if (!res.status) {
      return;
    }
    return res.data.info.main_domain || res.data.info.shop_domain;
  }
}

/**
 * 获取token
 * @returns token
 */
export function getToken() {
  return getUrlParams(Token.Token) || getUrlParams(Token.ApiToken) || '';
}
