import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

// import termgenerator from '@/app/termgenerator/router';
// import giftcard from '@/app/giftcard/router';
// import buypoints from '@/app/buypoints/router';
// import pricecutsremind from '@/app/pricecutsremind/router';
// import detailmarkettool from '@/app/detailmarkettool/router';
// import detailinquiry from '@/app/detailinquiry/router';
// import imageserach from '@/app/imagesearch/router';
import criteo from '@/app/criteo/router';
// import demo from '@/app/_demo/router';

const routes: Array<RouteRecordRaw> = [
  // termgenerator,
  // giftcard,
  // buypoints,
  // pricecutsremind,
  // detailmarkettool,
  // detailinquiry,
  // imageserach,
  criteo,
  // demo,
];

export default createRouter({
  history: createWebHistory(),
  routes,
});
